// extracted by mini-css-extract-plugin
export var location = "home-module--location--FKex3";
export var content = "home-module--content--uBGWe";
export var map = "home-module--map--fVZgp";
export var info = "home-module--info--hhYTe";
export var day = "home-module--day--YmH3b";
export var time = "home-module--time--bwmsL";
export var hero = "home-module--hero--K4NTb";
export var about = "home-module--about--xDYDa";
export var background = "home-module--background--7H42G";
export var covid = "home-module--covid--v0Cvi";
export var flex = "home-module--flex--DIGfo";
export var gallery = "home-module--gallery--CDmg9";
export var horizontal = "home-module--horizontal--fx2sW";
export var vertical = "home-module--vertical--hjAqv";
export var big = "home-module--big--6Oci3";
export var column = "home-module--column--T5mqy";
export var testimonial = "home-module--testimonial--R0EsT";
export var testimonialText = "home-module--testimonial__text--q+HVW";
export var testimonialJob = "home-module--testimonial__job--JFjQY";
export var testimonialName = "home-module--testimonial__name--OqZpV";
export var testimonialImg = "home-module--testimonial__img--4Hcg2";
export var testimonialInfo = "home-module--testimonial__info--oB6WG";
export var container = "home-module--container--aj9vI";
export var containerTestimonials = "home-module--container--testimonials--5dofo";